import _get from "lodash.get";
import { Col, Grid, Heading, Row } from "pws-design-system/design-system";
import React from "react";
import PageModel from "../../../models/prismic/page";
import RichText from "../../common/components/rich-text";
import UsePreviewDataStoreContainer from "../../common/hooks/stores/usePreviewDataStore";
import PageLayout from "../../common/layouts/PageLayout";
import Slices from "../../common/components/slices";
import { ThemeContainer } from "../../common/hooks/useTheme";
import { Providers } from "../../common/layouts/Layout";

export interface PageProps {
  pageContext?: any;
  // Custom data passed to page from Gatsby
  pathContext: {
    // data to hydrate the page with
    data: object;
  };
}

function Page({ pathContext }: PageProps): React.ReactElement {
  const { theme } = ThemeContainer.useContainer();
  const [previewData] = UsePreviewDataStoreContainer.useContainer();
  const data = previewData === null ? pathContext.data : _get(previewData, "pageData.prismicPage");
  const pageModel = new PageModel(data, "page");
  return (
    <PageLayout
      page={pageModel}
      metaTitle={pageModel.metaTitle}
      metaDescription={pageModel.metaDescription}
      headerProps={{ bg: theme.colors.bg.base.primary }}
      containerProps={{ bg: theme.colors.bg.base.primary }}
      contentProps={{ bg: theme.colors.bg.base.primary }}
    >
      <Grid pb={[null, null, 6]}>
        <Row>
          <Col sm={12} lg={12}>
            {pageModel.has("title") && (
              <Heading as="h1" variant="hero" mb={[4, null, 5]}>
                {pageModel.title}
              </Heading>
            )}
            {pageModel.has("content") && <RichText markup={pageModel.content} />}
            {pageModel.has("slices") && <Slices slices={pageModel.slices} />}
          </Col>
        </Row>
      </Grid>
    </PageLayout>
  );
}

export default function PageWrapped(props) {
  return (
    <Providers>
      <Page {...props}>{props.children}</Page>
    </Providers>
  );
}
